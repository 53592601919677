import React, { useContext } from 'react';
import { GlobalContext } from './GlobalContext';

const LoginPage = () => {
    const { globalState, setGlobalState } = useContext(GlobalContext);
    const handleKakaoButtonClick = () => {
        window.location.href = `${globalState.serverUrl}/member-service/v1/oauth/kakao?profile=${globalState.profile}`;
    };

    const handleNaverButtonClick = () => {
        window.location.href = `${globalState.serverUrl}/member-service/v1/oauth/naver?profile=${globalState.profile}`;
    };

    const handleGoogleButtonClick = () => {
        window.location.href = `${globalState.serverUrl}/member-service/v1/oauth/google?profile=${globalState.profile}`;
    };

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <button
                onClick={handleKakaoButtonClick}
                style={{padding: '10px 20px', fontSize: '18px', borderRadius: '5px', cursor: 'pointer'}}
            >
               카카오톡 로그인 
            </button>
            <button
                    onClick={handleNaverButtonClick}
                    style={{ padding: '10px 20px', fontSize: '18px', borderRadius: '5px', cursor: 'pointer' }}
                >
                    네이버 로그인
            </button>
            <button
                    onClick={handleGoogleButtonClick}
                    style={{ padding: '10px 20px', fontSize: '18px', borderRadius: '5px', cursor: 'pointer' }}
                >
                    구글 로그인
            </button>
        </div>
    );
};

export default LoginPage;