import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GlobalProvider } from './GlobalContext';
import LoginPage from "./LoginPage";
import KakaoRedirectPage from "./KakaoRedirectPage";
import NaverRedirectPage from './NaverRedirectPage';
import GoogleRedirectPage from './GoogleRedirectPage';

const App = () => {
    return (
        <GlobalProvider>
            <div className='App'>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<LoginPage />}></Route>
                        <Route path="/auth/kakao" element={<KakaoRedirectPage />}></Route>
                        <Route path="/auth/naver" element={<NaverRedirectPage />}></Route>
                        <Route path="/auth/google" element={<GoogleRedirectPage />}></Route>
                    </Routes>
                </BrowserRouter>
            </div>
        </GlobalProvider>
    );
};

export default App;